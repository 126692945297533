<template>
  <div class="base-div">
    <footer class="base">
      <div class="footer-top">
        <div class="footer-div">
          <router-link to="/">
            <img src="../../assets/logo.svg" alt/>
          </router-link>
        </div>
        <div class="footer-div footer-left">
          <div class="footer-title">产品</div>
          <ul class="footer-ul">
            <li>
              <router-link to="product" class="list-item">所有产品</router-link>
            </li>
            <li>
              <router-link to="faq" class="list-item">常见问题</router-link>
            </li>
          </ul>
        </div>
        <div class="footer-div">
          <div class="footer-title">联系</div>
          <ul class="footer-ul">
            <li>
              <router-link to="contact" class="list-item">邮件</router-link>
            </li>
          </ul>
        </div>
        <div class="footer-div">
          <div class="footer-title">服务</div>
          <ul class="footer-ul">
            <li>
              <router-link to="terms_service" class="list-item">服务条款</router-link>
            </li>
            <li>
              <router-link to="privacy_policy" class="list-item">隐私政策</router-link>
            </li>
          </ul>
        </div>
      </div>
      <small class="footer-small">
        <span style="padding-right: 20px">杭州文洁网络有限公司</span>
        <span>© 2020-2022 All Rights Reserved</span>
      </small>
      <small class="footer-small">
        <a href="http://beian.miit.gov.cn" target="_blank" rel="nofollow">
          <span style="padding-right: 14px">浙ICP备2020033661号</span>
        </a>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602011626" rel="nofollow">
          <img src="../../assets/beian.png" alt>
          浙公网安备33010602004354号
        </a>
      </small>
    </footer>
  </div>
</template>

<script>

  export default {
    name: 'footerDiv'
  }
</script>

<style lang="scss" scoped>
  * {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .base-div {
    background-color: #f6f9fc;
  }

  .base {
    margin: 0 auto;
    max-width: 1280px;
    padding: 50px 20px 20px;
  }

  .footer-top {
    display: flex;
    margin-bottom: 1rem;
  }

  .footer-div {
    flex-grow: 1;
    padding: 1rem;
    flex-basis: 0;
  }

  .footer-left {
    margin-left: 20%;
  }

  .footer-ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
  }

  .footer-title {
    text-align: left;
    font-size: 16px;
    color: #76808d;
    margin-bottom: 12px;
  }

  .list-item {
    color: #999;
    text-align: left;
    font-size: 14px;
    line-height: 30px
  }

  .list-item:hover {
    color: #333;
  }

  .footer-small {
    display: block;
    font-size: 12px;
    color: #b9b9b9;
    margin-bottom: 5px;
  }

  .footer-small a {
    font-size: 10px;
    color: #b9b9b9;
  }

  .footer-small img {
    width: 14px;
    vertical-align: text-bottom;
    display: inline-block;
  }

  .base {
    margin: 0 auto
  }

  @media screen and (max-width: 798px) {
    .footer-top {
      flex-direction: column;
    }
    .footer-left {
      margin-left: 0;
    }
  }

</style>
