<template>
  <div>
    <titleDiv></titleDiv>
    <div style="text-align: center;"><h1>未找到...</h1></div>
    <footerDiv></footerDiv>
  </div>
</template>
<script>

  import titleDiv from '../index/title';
  import footerDiv from '../index/footer'

  export default {
    name: "NotFound",
    components: {
      titleDiv,
      footerDiv
    }
  }
</script>
<style></style>