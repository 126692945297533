import Vue from 'vue'
import App from './App.vue';
import VueRouter from 'vue-router'
import NotFoundRoute from './components/extra/404.vue';

Vue.config.productionTip = false;
Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: () => import('./components/index/index'),
      meta: {title: '首页'}
    },
    {
      path: '/product',
      component: () => import('./components/product/product'),
      meta: {title: '产品'}
    },
    {
      path: '/product/verify',
      component: () => import('./components/product/verify'),
      meta: {title: '产品-有赞核销'}
    },
    {
      path: '/contact',
      component: () => import('./components/contact/contact'),
      meta: {title: '联系我们'}
    },
    {
      path: '/faq',
      component: () => import('./components/faq/faq'),
      meta: {title: '常见问题'}
    },
    {
      path: '/terms_service',
      component: () => import('./components/extra/terms_service'),
      meta: {title: '服务条款'}
    },
    {
      path: '/privacy_policy',
      component: () => import('./components/extra/privacy_policy'),
      meta: {title: '隐私政策'}
    },
    {
      path: "*",
      component: NotFoundRoute,
      meta: {title: 'Page Not Found'}
    },
  ],
});

new Vue({
  router,
  render: function (createElement) {
    return createElement(App);
  },
}).$mount('#app');
