<template>
  <div>
    <div class="title">
      <router-link to="/">
        <img src="../../assets/logo.svg" alt=""/>
      </router-link>
      <ul class="nav">
        <li class="nav-li">
          <router-link to="product" class="nav-item">产品</router-link>
        </li>
        <li class="nav-li">
          <router-link to="contact" class="nav-item">联系我们</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "titles"
  };
</script>

<style lang="scss" scoped>
  * {
    padding: 0;
    margin: 0;
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  .title {
    padding: 20px 60px;
    display: flex;
    justify-content: space-between;
    max-width: 1280px;
    margin: 0 auto;
  }

  .nav-li {
    display: inline-block;
    margin: 0 18px;
  }

  .nav-item {
    font-size: 15px;
    color: #919dad;
    list-style: none;

    &.router-link-active {
      color: black;
    }
  }

  .nav-item:hover {
    color: #000;
  }

  @media screen and (max-width: 798px) {
    .title {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 20px 28px;
    }
    .nav {
      margin-top: 10px;
    }
    .nav-li {
      margin: 0 10px;
    }
  }
</style>
